import { Poll } from '@vgno/widgets/Poll';
import { render } from 'preact';

export default async (props) => {
    const { hasSession, login } = props;
    const { pollData } = props.props;
    const user = await hasSession()
        .then((session) => session)
        .catch(() => null);
    return render(
        <Poll
            answers={pollData.answers}
            id={pollData.id}
            user={user}
            login={() => login(window.location.href, 'poll')}
            label=""
            question={pollData.question}
            requiresLogin={true}
            subtype={pollData.subtype}
        />,
        props.node,
    );
};
